import React from "react";
import SectionHeader from "./SectionHeader";
import { useNavigate } from "react-router";
import { useViewport } from "../../utils/context/viewport-context";
import Header from "../header";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { addPathSeparators, isTamil } from "../../utils/helpers";
import useTranslation from "../../utils/hooks/use-translation";

const CommonSection = ({ children, ...props }) => {
  const { t } = useTranslation();
  const siteType = useSelector(
    (state) => state?.common?.siteType && state?.common?.siteType.siteType
  );
  const { isMobile } = useViewport();
  const { pathname } = useLocation();
  const { headerContent = {}, headerType = "", sectionClass = "" } = props;
  const navigate = useNavigate();
  const isHeaderVisible = [
    `${addPathSeparators("/login")}`,
    `${addPathSeparators("/signup")}`,
    `${addPathSeparators("/otp_verification")}`,
  ].includes(pathname);
  const { pageHeaderClass = "", headerClass = "", containerClass = "" } = props;
  return (
    <>
      {!isMobile && isHeaderVisible && headerType === 1 && <Header />}
      {isMobile && (
        <div className={`page-header ${pageHeaderClass}`}>
          {/* Back button  notification*/}
          {headerType !== 2 && (
            <span
              className="icon icon-back icon-Back-01"
              onClick={() => navigate(-1)}
            ></span>
          )}
          {/* Enable for logo only  login */}
          {headerType === 1 && (
            <div className="page-header-bottom justify-content-center pt-0">
              <h1 title={isTamil() ? t.common.h1TTitle : t.common.h1BTitle}>
                <img
                  style={{
                    width: window.innerWidth > 720 ? 77 : 52,
                  }}
                  className="page-header-logo page-header-logo-md"
                  src={`/assets/img/icons/${siteType}-logo${
                    siteType === "bharat" ? "-new.png" : ".svg"
                  }`}
                  alt="Logo"
                  onClick={() => navigate(addPathSeparators("/"))}
                />
              </h1>
            </div>
          )}
          {/* Enable for logo and close button  claim header*/}
          {headerType === 2 && (
            <div className="page-header-bottom">
              <h1 title={isTamil() ? t.common.h1TTitle : t.common.h1BTitle}>
                <img
                  style={{
                    width: window.innerWidth > 720 ? 77 : 52,
                  }}
                  className="page-header-logo"
                  src={`/assets/img/icons/${siteType}-logo${
                    siteType === "bharat" ? "-new.png" : ".svg"
                  }`}
                  alt="Logo"
                  onClick={() => navigate(addPathSeparators("/"))}
                />
              </h1>
              <span
                className="icon-close icon-Cross-01"
                onClick={() => navigate(-1)}
              ></span>
            </div>
          )}
          {/* Enable section header  */}
          {headerContent &&
            headerType === 3 &&
            Object.keys(headerContent).length > 0 && (
              <SectionHeader
                {...headerContent}
                headerClass={`pt-2 pb-0 ${headerClass}`}
                containerClass={"px-0"}
              />
            )}
        </div>
      )}

      <div className={`container-fluid ${containerClass}`}>
        <div className="row justify-content-center">
          <section className={`col-12 ${sectionClass}`}>{children}</section>
        </div>
      </div>
    </>
  );
};

export default CommonSection;
