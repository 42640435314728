import { addPathSeparators, manageKaraokePath } from "../helpers";
let siteType = window.location.pathname.includes("/tamil") ? "tamil" : "bharat";
// let tamilShow = siteType === 'bharat';
// let bharatShow = siteType === 'tamil';

export const bannerData = [];

export const latesteTuneData = [
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img01",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img03",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img04",
  },
];

export const behindTheSceneData = [
  {
    image: "/assets/img/behindthescene/img01.webp",
    altText: "img01",
  },
  {
    image: "/assets/img/behindthescene/img01.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/behindthescene/img01.webp",
    altText: "img03",
  },
  {
    image: "/assets/img/latestunes/img01.webp",
    altText: "img04",
  },
];
export const liveStreamingData = [
  {
    image: "/assets/img/livestreaming/img01.webp",
    altText: "img01",
  },
];
export const melodiesRewindData = [
  {
    image: "/assets/img/seasonalhits/img01.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/seasonalhits/img02.webp",
    altText: "img02",
  },
  {
    image: "/assets/img/seasonalhits/img03.webp",
    altText: "img03",
  },
];

export const rewardPooldata = [
  {
    image: "assets/img/rewards/img01.webp",
    title: "Win a Ticket to Tomorrowland",
    text: "Stand a chance to win an all expenses paid trip to Europe and experience Tomorrowland in person",
    points: "200",
    pointsCount: "1",
    pointTitle: "By Using",
    buttonText: "Collect Now",
  },
  {
    image: "assets/img/rewards/img02.webp",
    title: "Win Music Fest Ticket3",
    text: "Stand a chance to win a ticket to experience Music Fest.",
    points: "202",
    pointsCount: "2",
    pointTitle: "By Using 2",
    buttonText: "Collect Now",
  },
  {
    image: "assets/img/rewards/img03.webp",
    title: "Win Music Fest Ticket",
    text: "Stand a chance to win a ticket to experience Music Fest.",
    points: "2003",
    pointsCount: "3",
    pointTitle: "By Using 3",
    buttonText: "Collect Now",
  },
  {
    image: "assets/img/rewards/img01.webp",
    title: "Win Music Fest Ticket 4",
    text: "Stand a chance to win a ticket to experience Music Fest.",
    points: "2004",
    pointsCount: "4",
    pointTitle: "By Using 4",
    buttonText: "Collect Now",
  },
];

export const walletdata = [
  {
    title: "Harmony",
    points: "200",
    totalPoints: "1000",
    cardClassName: "Harmony",
  },
  {
    title: "Bass",
    points: "202",
    totalPoints: "1001",
    cardClassName: "Bass",
  },
  {
    title: "Aldo",
    points: "2003",
    totalPoints: "1002",
    cardClassName: "Aldo",
  },
  {
    title: "Echo",
    points: "2004",
    totalPoints: "1003",
    cardClassName: "Echo",
  },
];

export const treasureData = [
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Mass Merch",
    likes: "3",
    views: "5",
  },
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Class Merch",
    likes: "5",
    views: "8",
  },
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Mass Merch",
    likes: "9",
    views: "3",
  },
  {
    image: "/assets/img/treasures/img01.webp",
    title: "Class Merch",
    likes: "38",
    views: "500",
  },
];

export const headsData = [
  {
    image: "/assets/img/studioheads/img01.webp",
    title: "Mass Merch",
    likes: "3",
    views: "5",
  },
  {
    image: "/assets/img/studioheads/img02.webp",
    title: "Class Merch",
    likes: "5",
    views: "8",
  },
  {
    image: "/assets/img/studioheads/img03.webp",
    title: "Mass Merch",
    likes: "9",
    views: "3",
  },
  {
    image: "/assets/img/studioheads/img03.webp",
    title: "Class Merch",
    likes: "38",
    views: "500",
  },
];

export const artistList = [
  {
    id: 1,
    name: "Afroto",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians. Batiste studied and received both a B.A. and M.F.A. at the world-renowned Juilliard School in New York City. In 2020, he won an Academy Award for Best Original Score for the Disney/Pixar film SOUL, an honor he shared with fellow composers Trent Reznor and Atticus Ross. He is the second black composer in history, after legendary jazz musician Herbie Hancock, to win an Academy Award for composition. Batiste's latest studio album, WE ARE, was released in March 2021 to overwhelming critical acclaim.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
  {
    id: 2,
    name: "Camilo",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 100,
    views: 4000,
    likes: 60,
  },
  {
    id: 3,
    name: "Cat Burns",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 30,
    views: 2000,
    likes: 60,
  },
  {
    id: 4,
    name: "Afroto",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
  {
    id: 5,
    name: "Camilo",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
  {
    id: 6,
    name: "Cat Burns",
    title: "Learn more about your favorite artist",
    description:
      "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
    linkText: "Explore",
    image: "/assets/img/artist.png",
    popularity: 120,
    views: 1000,
    likes: 500,
  },
];

export const karaokeData = [
  {
    id: 1,
    image: "/assets/img/karaokeon/img01.webp",
    title: "Get Your Karaoke On",
    description:
      "Use the exclusive Coke® Studio Karaoke to record yourself singing alongto your favorites, share with your friends and brag away!",
    linkText: "Let's Start",
  },
];

export const menuData = (t) => {
  // let karaokeType = siteType === 'tamil' ? 'karaoke' : 'udja';

  let data = [
    {
      id: 1,
      image: "/assets/img/icons/home.svg",
      title: t.header.home,
      url: addPathSeparators("/"),
    },
    {
      id: 2,
      image: "/assets/img/icons/participate.svg",
      title: t.header.participate_and_win,
      url: addPathSeparators("/contest"),
      authentication: true,
    },
    {
      id: 3,
      image: "/assets/img/icons/explore.svg",
      title: t.header.explore,
      url: "#",
      subMenu: [
        {
          id: 1,
          title: t.header.coke_treasures,
          url: addPathSeparators("/see_all/vedio_card/coke_studio_exclusive"),
          isAction: false,
        },
        {
          id: 2,
          title: t.header.artist,
          url: addPathSeparators("/artist"),
          isAction: false,
        },
        {
          id: 2,
          title: t.header.melodies_rewind,
          url: addPathSeparators("/see_all/vedio_card/melodies_rewind"),
          isAction: false,
        },
        // {
        //   id: 3,
        //   title: t.header.coke_heads,
        //   url: addPathSeparators("/ugc"),
        // },
        // {
        //   id: 4,
        //   title: t.header.seasonal_hits,
        //   url: addPathSeparators('/#seasonal_hits'),
        //   isAction: false
        // },

        {
          id: 5,
          title: t.header.behind_the_scene,
          url: addPathSeparators("/see_all/vedio_card/behind_the_scene"),
          isAction: false,
        },

        // Conditionally include Passport field based on isPassportFieldShow
        ...(siteType === "bharat"
          ? [
              {
                id: 6,
                title: t.header.coke_tamil,
                url: "/tamil",
                isAction: false,
              },
            ]
          : []),
        ...(siteType === "tamil"
          ? [
              {
                id: 7,
                title: t.header.coke_bharat,
                url: "/bharat",
                isAction: false,
              },
            ]
          : []),
        {
          id: 8,
          title: t.header.coke_bangla,
          url: "https://www.youtube.com/c/cokestudiobangla",
        },
        // {
        //   id: 9,
        //   title: t.header.coke_international,
        //   url: "https://www.coca-cola.com/in/en/offerings/coke-studio",
        //   isAction: false,
        // },
        {
          id: 10,
          title: t.header.karaok,
          url: manageKaraokePath(),
          isAction: true,
          // url: addPathSeparators('/KaraokesongList'),
          //url:`http://localhost:3003/AWSBeanstalkHelloWorldWebApp_deploy/karaoke/${karaokeType}-song`
        },
        // {
        //   id: 11,
        //   title: t.header.live_stream,
        //   url: addPathSeparators('/#live_streams'),
        // },
        // {
        //   id: 12,
        //   title: t.header.ar,
        //   url: addPathSeparators('/'),
        // },
        {
          id: 13,
          title: t.header.refer_a_friend,
          isAction: true,
          // url: '#'
          //url:addPathSeparators('/my_profile/#refer_friend')
        },
      ],
    },
  ];

  return data;
};

export const menuData2 = (t) => {
  let data = [
    // {
    //   id: 1,
    //   image: '/assets/img/icons/sitemap.svg',
    //   title: t.header.sitemap,
    //   url: addPathSeparators('/site_map'),
    // },
    {
      id: 1,
      image: "/assets/img/icons/contactus.svg",
      title: t.header.contact_us,
      url: addPathSeparators("/contactUs"),
      type: "contact_us",
    },
    {
      id: 2,
      image: "/assets/img/icons/faq.svg",
      title: t.header.faq,
      url: addPathSeparators("/faq"),
    },
    {
      id: 3,
      image: "/assets/img/icons/tnc.svg",
      title: t.header.terms_and_condiotions,
      url: addPathSeparators("/terms_of_use"),
    },
    {
      id: 4,
      image: "/assets/img/icons/privacyPolicy.svg",
      title: t.header.privacy_policy,
      url: addPathSeparators("/privacy_policy"),
    },
  ];
  return data;
};

export const getModelType = (
  t,
  modelType,
  rewardType,
  subheading,
  onAction
) => {
  const modelContent = [
    {
      image: "/assets/img/modal/hi-icon.svg",
      heading: t.model_messages.hi_there,
      subHeading: t.model_messages.thank_you_submit_entry,
      timer: false,
      cta: t.model_messages.got_it,
      description: "",
      id: 1,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/karaoke-submission.svg",
      heading: t.model_messages.congratulations,
      subHeading: t.model_messages.karaoke_submission_success,
      timer: false,
      cta: t.model_messages.got_it,
      description: "",
      id: 2,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/airpod.svg",
      heading: t.model_messages.congratulations,
      subHeading: t.model_messages.eligable_for_airpods,
      timer: true,
      cta: t.model_messages.got_it,
      description: "",
      id: 3,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/daily-limit.svg",
      heading: t.model_messages.sorry,
      subHeading: subheading,
      //t.model_messages.daily_limit_reached,
      timer: false,
      cta: "",
      description: t.model_messages.try_again_tomorrow,
      id: 4,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/missed-chance.svg",
      heading: t.model_messages.ohh_no,
      subHeading: `${t.model_messages.claim_timer_over}`,
      timer: false,
      cta: "",
      description: t.model_messages.try_again_later,
      id: 5,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/cross-icon.svg",
      heading: t.model_messages.ohh_no,
      subHeading: t.model_messages.answer_incorrect,
      timer: false,
      cta: t.model_messages.got_it,
      description: "",
      timerContent: t.model_messages.better_luck_next_time,
      id: 6,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/right-icon.svg",
      heading: t.model_messages.congratulations,
      subHeading: t.model_messages.answer_correct,
      timer: true,
      cta: t.model_messages.got_it,
      description: "",
      id: 7,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/right-icon.svg",
      heading: t.model_messages.congratulations,
      subHeading: `${t.model_messages.successful_claim_iphone} ${rewardType}`,
      timer: false,
      cta: "",
      description: t.model_messages.reach_you_soon,
      id: 8,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/right-icon.svg",
      heading: t.model_messages.congratulations,
      subHeading: `${t.model_messages.eligable_for_iphone} ${rewardType}!`,
      timer: false,
      cta: "",
      description: t.model_messages.inform_you_again,
      id: 9,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/hey-ticket.svg",
      heading: t.model_messages.hi_there,
      subHeading: t.model_messages.eligable_for_CSK_VIP,
      timer: true,
      cta: t.model_messages.got_it,
      description: "",
      id: 10,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/ticket-eligible.svg",
      heading: t.model_messages.congratulations,
      subHeading: t.model_messages.eligable_for_CSK_VIP,
      timer: false,
      cta: "",
      description: "",
      timerContent: t.model_messages.start_quiz_claim,
      id: 11,
      onAction: () => onAction(),
    },
    {
      image: "/assets/img/modal/daily-limit.svg",
      heading: t.model_messages.sorry,
      subHeading: subheading,
      timer: false,
      cta: "",
      description: t.model_messages.daily_limit_reached,
      id: 12,
      onAction: () => onAction(),
    },
  ];
  let contentType = modelContent.find((el) => el.id === modelType);
  return contentType;
};

export const dummyLyrics = `[00:05.80]Les gens qui voient de travers
[00:07.51]Pensent que les bancs verts
[00:09.05]Qu'on voit sur les trottoirs
[00:10.98]Sont faits pour les impotents ou les ventripotents
[00:16.59]Mais c'est une absurdité
[00:17.48]Car à la vérité
[00:19.35]Ils sont là c'est notoire
[00:21.93]Pour accueillir quelque temps les amours débutants
[00:26.75]Les amoureux qui s'bécott'nt sur les bancs publics
[00:29.56]
[00:30.11]Bancs publics bancs publics
[00:32.87]En s'fouttant pas mal du regard oblique
[00:35.52]Des passants honnêtes
[00:37.67]Les amoureux qui s'bécott'nt sur les bancs publics
[00:40.98]Bancs publics bancs publics
[00:43.63]En s'disant des Je t'aime pathétiques
[00:45.98]Ont des p'tit's gueul' bien sympatiques
[00:48.77]Ils se tiennent par la main
[00:50.66]Parlent du lendemain
[00:51.81]Du papier bleu d'azur
[00:54.21]Que revêtiront les murs de leur chambre à coucher
[00:59.86]Ils se voient déjà doucement
[01:01.64]Ell' cousant lui fumant
[01:02.72]Dans un bien être sûr
[01:05.24]Et choisissent les prénoms de leur premier bébé
[01:10.05]Les amoureux qui s'bécott'nt sur les bancs publics
[01:13.25]Bancs publics bancs publics
[01:15.96]En s'fouttant pas mal du regard oblique
[01:18.68]Des passants honnêtes
[01:20.67]Les amoureux qui s'bécott'nt sur les bancs publics
[01:23.97]Bancs publics bancs publics
[01:26.72]En s'disant des Je t'aime pathétiques
[01:29.36]Ont des p'tit's gueul' bien sympatiques
[01:31.97]Quand la saint' famill' machin
[01:33.72]Croise sur son chemin
[01:34.71]Deux de ces malappris
[01:37.38]Ell' leur décoche hardiment des propos venimeux
[01:43.01]N'empêch' que tout' la famille
[01:44.87]Le pèr' la mèr' la fille
[01:46.83]Le fils le Saint Esprit
[01:48.56]Voudrait bien de temps en temps pouvoir s'conduir' comme eux
[01:53.14]Les amoureux qui s'bécott'nt sur les bancs publics
[01:56.33]Bancs publics bancs publics
[01:59.05]En s'fouttant pas mal du regard oblique
[02:01.90]Des passants honnêtes
[02:03.92]Les amoureux qui s'bécott'nt sur les bancs publics
[02:07.14]Bancs publics bancs publics
[02:09.76]En s'disant des Je t'aime pathétiques
[02:12.51]Ont des p'tit's gueul' bien sympatiques
[02:15.22]Quand les mois auront passé
[02:16.97]Quand seront apaisés
[02:18.11]Leurs beaux rêves flambants
[02:20.61]Quand leur ciel se couvrira de gros nuages lourds
[02:26.17]Ils s'apercevront émus
[02:28.15]Qu' c'est au hasard des rues
[02:29.24]Sur un d'ces fameux bancs
[02:31.49]Qu'ils ont vécu le meilleur morceau de leur amour
[02:36.46]Les amoureux qui s'bécott'nt sur les bancs publics
[02:39.65]Bancs publics bancs publics
[02:42.31]En s'fouttant pas mal du regard oblique
[02:45.04]Des passants honnêtes
[02:47.30]Les amoureux qui s'bécott'nt sur les bancs publics
[02:50.45]Bancs publics bancs publics
[02:53.15]En s'disant des Je t'aime pathétiques
[02:55.80]Ont des p'tit's gueul' bien sympatiques`;
