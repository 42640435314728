import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";
import gluedin from "gluedin";

const feedModule = new gluedin.GluedInFeedModule();
const authModule = new gluedin.GluedInAuthModule();

//get all the details of home page
export const getHomepageDetailsBeforeLogin = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.homeDetails}?languageId=${data.languageId}`
  );
};

//get all the details of home page
export const getHomepageDetailsAfterLogin = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.homeDetailsAfterLogin}?languageId=${data.languageId}`
  );
};

//get all the the behind the scene
export const getAllBehindTheSceneDataAPI = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getAllBehindThescneData}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
  );
};

//get all the the melodies
export const getAllMelodieDataAPI = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getAllMelodies}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&seasonId=${data.seasonId}`
  );
};

//get all treasures data
export const getAllUGCDataAPI = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getAllUGC}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&UGCType=${data.UGCType}`
  );
};

//get all treasures data
export const getAllTreasuresDataAPI = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getAllTreasures}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
  );
};

//get all season list data
export const getAllSeasionsList = async (data) => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getAllTheSeasons}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`
  );
};

//call this api one user view vedio at the nd
export const sentUserViewedVedio = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.userViewedVedio}`,
    data
  );
};

// like vedio by id
export const likeVedioById = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.likeVedios}`,
    data
  );
};

// unicode api integration
export const mixCodeApi = async (data) => {
  return await AxiosInstance.post(
    `${version}${API_END_POINT.uniqueCode}`,
    data
  );
};

// song drop api integration
export const songDropAPI = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.songDrop}`);
};

// song drop api integration
export const jioSawanCodeAPI = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.jioSawan}`, data);
};

// Corrected fetchFeed function
export const fetchFeed = async (railId, isLoggedIn) => {
  try {
    var curatedContentModuleObj = new gluedin.GluedInCuratedContentModule();
    var curatedContentModuleResponse =
      await curatedContentModuleObj.getRailById(railId);

    if (curatedContentModuleResponse.status === 200) {
      const curatedResponse = curatedContentModuleResponse.data.result;

      if (
        curatedResponse &&
        curatedResponse.itemList &&
        curatedResponse.itemList.length
      ) {
        const feedArray = curatedResponse.itemList.map((contentInfo) => ({
          ...contentInfo.video,
        }));
        let accessToken = "";
        let likedVideos = [];
        if (isLoggedIn) {
          try {
            accessToken = authModule.getAccessToken();
          } catch (error) {
            //localStorage.clear()
          }
          if (accessToken) {
            let likedVideoIds = feedArray.map((item) => item.videoId);
            if (likedVideoIds && likedVideoIds.length > 0) {
              let likeVideosResponse = await feedModule.Like(likedVideoIds);
              if (
                likeVideosResponse.status === 200 ||
                likeVideosResponse.status === 201
              ) {
                likedVideos = likeVideosResponse.data.result;
              }
            } else {
              likedVideos = [];
            }
          }
        }

        // Merge curatedResponse and likedVideos into a single object
        const combinedResponse = {
          ...curatedResponse,
          likedVideos: likedVideos,
        };
        return combinedResponse;
      } else {
        throw new Error("Failed to fetch feed for rail ID: " + railId);
      }
    }
  } catch (error) {
    throw error;
  }
};
// Function to fetch feeds for multiple rail IDs
export const fetchFeedsForRailIds = async (railIds, isLoggedIn) => {
  try {
    const feedPromises = railIds.map(async (railId) => {
      try {
        return await fetchFeed(railId, isLoggedIn);
      } catch (error) {
        // Return a default value or null to indicate failure for this specific rail ID
        return null;
      }
    });

    const feeds = await Promise.all(feedPromises);

    // Filter out null values indicating failed API calls
    const successfulFeeds = feeds.filter((feed) => feed !== null);

    return successfulFeeds;
  } catch (error) {
    // Return an empty array or any default value to indicate failure
    return [];
  }
};

// get ugc feeds
export const getUgcFeeds = async (isLoggedIn, payload) => {
  try {
    const resData = await feedModule.searchVideo(payload);
    if (resData.status === 200) {
      const curatedResponse = resData.data.result;
      if (curatedResponse && curatedResponse.length) {
        const feedArray = curatedResponse.map((contentInfo) => ({
          ...contentInfo.video,
        }));
        const videoList = curatedResponse
          .filter((uv) => uv.contentType === "video")
          .map((uv) => ({
            video: uv,
            contentType: uv.contentType,
          }));
        let accessToken = "";
        let likedVideos = [];
        if (isLoggedIn) {
          try {
            accessToken = authModule.getAccessToken();
          } catch (error) {
            //localStorage.clear()
          }
          if (accessToken) {
            let likedVideoIds = feedArray.map((item) => item.videoId);
            if (likedVideoIds && likedVideoIds.length > 0) {
              let likeVideosResponse = await feedModule.Like(likedVideoIds);
              if (
                likeVideosResponse.status === 200 ||
                likeVideosResponse.status === 201
              ) {
                likedVideos = likeVideosResponse.data.result;
              }
            } else {
              likedVideos = [];
            }
          }
        }

        // Merge curatedResponse and likedVideos into a single object
        const combinedResponse = {
          itemList: videoList,
          likedVideos: likedVideos,
          railId: "d588d751-2673-4c09-b3b8-a1098c65692789",
        };
        return combinedResponse;
      } else {
        throw new Error("Failed to fetch feed: ");
      }
    }
  } catch (error) {
    throw error;
  }
};
